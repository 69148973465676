import { useCallback } from 'react';

import { WizardStepHook, WizardStepHookProps } from '../../../../core/Wizard';
import { useDevLauncherWizard } from '../useDevLauncherWizard';
import { DevLauncherWizardSchema } from '../DevLauncherWizardSchema';

import { useConfirmStepStatus } from './useConfirmStepStatus';

export const useConfirmStep = ({
  initialiseOnMount,
}: WizardStepHookProps = {}): WizardStepHook<DevLauncherWizardSchema, 2> => {
  const { wizard } = useDevLauncherWizard();
  const state = wizard.stepsState[2];
  const { isInitialised, isComplete } = useConfirmStepStatus();

  const initialise = useCallback(async () => {}, []);

  const isInitialising = false;

  return {
    state,
    initialise,
    isInitialising,
    isInitialised,
    isComplete,
  };
};
