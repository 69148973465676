import { useCallback } from 'react';

import { useWizard } from '@reckon-web/wizard-hooks';

import {
  DEVLAUNCHER_WIZARD_INITIAL_STATE,
  DEVLAUNCHER_WIZARD_KEY,
  DevLauncherWizardSchema,
} from './DevLauncherWizardSchema';

export const useDevLauncherWizard = () => {
  const wizard = useWizard<DevLauncherWizardSchema>(DEVLAUNCHER_WIZARD_KEY);

  if (!wizard) throw Error('wizard not found');

  const resetWizard = useCallback(() => {
    wizard.setWizardState((state) => {
      state[1].form.setState({
        ...state[1].form.state,
        email: {
          touched: false,
          value: '',
        },
        password: {
          touched: false,
          value: '',
        },
      });

      return {
        stepsState: {
          1: { ...DEVLAUNCHER_WIZARD_INITIAL_STATE[1], form: state[1].form },
          2: state[2],
        },
        additionalStepState: void 0,
      };
    });
  }, [wizard]);

  return { wizard, resetWizard };
};
