import { useEffect } from 'react';
import { useLocalstorageState } from 'rooks';
import useHashParam from 'use-hash-param';

import { WizardType } from '@reckon-web/wizard-hooks';

import { serialiseWizardState } from '../../../core/Wizard';

import {
  DEVLAUNCHER_WIZARD_LOCALSTORAGE_KEY,
  DevLauncherWizardSchema,
} from './DevLauncherWizardSchema';
type UseDevLauncherPersistStateProps = {
  wizard: WizardType<DevLauncherWizardSchema>;
};
export const useDevLauncherPersistState = ({
  wizard,
}: UseDevLauncherPersistStateProps) => {
  // Persist wizard state
  const { setStepUrlParam } = useDevLauncherStepUrlParam('1');
  const [, setSerialisedState] = useLocalstorageState<any>(
    DEVLAUNCHER_WIZARD_LOCALSTORAGE_KEY
  );
  useEffect(() => {
    const serialisedWizardState = serialiseWizardState({ wizard });
    setSerialisedState(serialisedWizardState);
    setStepUrlParam(wizard.currentStep.toString());
  }, [setStepUrlParam, setSerialisedState, wizard]);
};

export function useDevLauncherStepUrlParam(initialStep = '1') {
  const [param, setParam] = useHashParam('step', initialStep);
  return {
    setStepUrlParam(value?: string) {
      setParam(value || '');
    },
    stepUrlParam: (Number(param) as unknown) as keyof DevLauncherWizardSchema,
  };
}
