import { cloneDeep, isObject } from 'lodash';
import { typedKeys } from '@balance-web/utils';

import { WizardType } from '@reckon-web/wizard-hooks';

import { isForm } from './types';

type SerialiseWizardStateProps<
  TWizardSteps,
  TAdditionalState extends void | object = void
> = {
  wizard: WizardType<TWizardSteps, TAdditionalState>;
  prettify?: boolean;
};
export const serialiseWizardState = <
  TWizardSteps extends object,
  TAdditionalState extends object | void = void
>({
  wizard,
  prettify,
}: SerialiseWizardStateProps<TWizardSteps, TAdditionalState>) => {
  return JSON.stringify(
    {
      stepsState: sanitiseStepsState(wizard.stepsState),
      additionalState: wizard.additionalState,
    },
    null,
    prettify ? 2 : 0
  );
};

const traverseStepsState = (state: object) => {
  if (isObject(state)) {
    if (!isForm(state)) {
      typedKeys(state).forEach((k) => traverseStepsState(state[k]));
    } else {
      typedKeys(state).forEach((k) => {
        if (k !== 'value') delete state[k];
      });
    }
  }
};

const sanitiseStepsState = <T extends object>(state: T): T => {
  let clonedState = cloneDeep(state);
  traverseStepsState(clonedState);
  return clonedState;
};
