import { useMemo } from 'react';

import { useAuth } from '../../../../services/Auth';
import { useDevLauncherWizard } from '../useDevLauncherWizard';

// Hook responsible for initialising, holding computed states for a step and any business logic

export const useConfirmStepStatus = () => {
  const { wizard } = useDevLauncherWizard();
  const { isAuthenticated } = useAuth();
  const state = wizard.stepsState[1];

  const isInitialised = useMemo(() => {
    return !!state.token?.length;
  }, [state.token]);

  const isComplete = useMemo(() => {
    return isInitialised && state.form?.validity === 'valid' && isAuthenticated;
  }, [isAuthenticated, isInitialised, state.form?.validity]);

  return {
    isInitialised,
    isComplete,
  };
};
