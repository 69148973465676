/**
 * @jsx jsx
 */
import React from 'react';
import { jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import { Text } from '@balance-web/text';
import { IndicatorDot } from '@balance-web/badge';

import { useDevLauncherWizard } from './useDevLauncherWizard';
import { DevLauncherWizardSchema } from './DevLauncherWizardSchema';

export const WizardStepButton = ({
  step,
  isInitialised,
  isComplete,
  title,
}: {
  step: keyof DevLauncherWizardSchema;
  isComplete?: boolean;
  isInitialised?: boolean;
  title: string;
}) => {
  const {
    wizard: { currentStep, setCurrentStep },
  } = useDevLauncherWizard();
  const isCurrent = currentStep === step;
  return (
    <Box
      key={step}
      background={isComplete ? 'positive' : isCurrent ? 'active' : 'muted'}
      padding={isCurrent ? 'medium' : 'none'}
      css={{
        position: 'relative',
        borderRadius: 100,
        minWidth: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={() => {
        setCurrentStep(step);
      }}
    >
      {!isComplete ? null : (
        <Box css={{ position: 'absolute', top: 0, right: 0 }}>
          <IndicatorDot tone="positive" />
        </Box>
      )}

      <Text color={isComplete ? 'positive' : 'active'}>
        {isCurrent ? title : step}
      </Text>
    </Box>
  );
};
