import type { PropsWithChildren } from 'react';
import React from 'react';
import '@reckon-web/gql-api-client';

import { GqlApiClientProvider } from '@reckon-web/gql-api-client';

import { Config } from '../Config';
import { result as introspectionQueryResultData } from '../../../__generated__/ts-gql/@introspection';

export const PlatformApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <GqlApiClientProvider
      url={Config.APPLICATION_APOLLO_CLIENT_API_URL}
      name={Config.APPLICATION_APOLLO_CLIENT_NAME}
      operationPrefixNameForAddingToRawLogOutputs={
        Config.APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME
      }
      version={Config.APPLICATION_VERSION}
      schema={introspectionQueryResultData}
    >
      {children}
    </GqlApiClientProvider>
  );
};
