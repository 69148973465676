import { useEffect } from 'react';
import { cloneDeep, get, isEqual, isObject } from 'lodash';
import { typedKeys } from '@balance-web/utils';

import { WizardType } from '@reckon-web/wizard-hooks';

import type { RecursivePartial, WizardState } from './types';
import { isForm } from './types';

export const useWizardFormStateSync = <T extends any = object>(
  wizard: WizardType<T, any>,
  stepForms: RecursivePartial<WizardState<T, never>['stepsState']>
) => {
  useEffect(() => {
    const formPathKeys = findFormPathsInState(stepForms);

    formPathKeys.forEach((key) => {
      const splitKey = key.split('.') as [keyof T, ...(keyof T[keyof T])[]];
      const currentStepState = get(wizard.stepsState, key);
      const newFormState = get(stepForms, key);
      const step = splitKey[0];

      if (hasStateChanged(currentStepState, newFormState)) {
        wizard.setStepState(step, (prevState) => {
          const newState = cloneDeep(prevState);

          if (splitKey.length === 2) {
            const lastPart = splitKey[1];
            newState[lastPart] = newFormState;
          } else {
            const [leaf, ...parent] = key.split('.').reverse();

            get(newState, parent.splice(1).join('.'))[leaf] = newFormState;
          }

          return newState;
        });
      }
    });
  }, [wizard, stepForms]);
};

const hasStateChanged = (a: any, b: any): boolean => {
  if (isObject(a) && isObject(b)) {
    const isAForm = isForm(a);
    const isBForm = isForm(b);

    if ((isAForm && !isBForm) || (isBForm && !isAForm))
      throw Error(
        'Cannot compare state of form and plain objects. There is a mismatch in your state schema.'
      );

    if (isForm(a) && isForm(b)) {
      return !isEqual(a.value, b.value);
    }
    const keys = typedKeys(a);

    return keys.reduce<boolean>((results, key) => {
      return results || hasStateChanged(a[key], b[key]);
    }, false);
  }

  return !isEqual(a, b);
};

const findFormPathsInState = <T extends object>(object: T): Array<string> => {
  const invalidTypes = ['undefined', 'number', 'string'];

  if (invalidTypes.includes(typeof object)) {
    throw new Error('Invalid entry variable.');
  }

  return typedKeys(object).reduce<string[]>((prev, key) => {
    const thing = object[key];
    if (typeof thing !== 'object' || isForm(thing)) {
      return [...prev, key.toString()];
    }

    const paths = findFormPathsInState(thing as any).map(
      (item: string | number | symbol) =>
        `${key.toString()}.${
          typeof item === 'symbol' ? item.description : item.toString()
        }`
    );

    return [...prev, ...paths];
  }, []);
};
