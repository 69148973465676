/**
 * @jsx jsx
 */

import React, { useCallback } from 'react';
import { jsx } from '@balance-web/core';

import { useAuth } from '../../../../services/Auth';
import { useAuthenticateUserMutation } from '../../../../services/Auth';
import { useDevLauncherWizard } from '../useDevLauncherWizard';

import { useUserStep } from './useUserStep';
import { PickUserWizardStep } from './PickUserWizardStep';

export const PickUserWizardStepContainer = () => {
  const { state } = useUserStep();
  const { wizard } = useDevLauncherWizard();
  const { setCredentials } = useAuth();
  const {
    login,
    loading: isLoading,
    error: authError,
  } = useAuthenticateUserMutation();
  const errors = authError?.graphQLErrors?.map((error) => error.message);

  const handleSubmit = useCallback(async () => {
    if (!state.form?.validity) return;
    const { token } = await login(state.form?.value);
    if (!token) return;

    setCredentials({ accessToken: token });

    wizard.setStepState(wizard.currentStep, (state) => ({
      ...state,
      token,
    }));
    wizard.nextStep();
  }, [state.form?.validity, state.form?.value, login, setCredentials, wizard]);

  if (wizard.currentStep !== 1) return null;

  return (
    <PickUserWizardStep
      errors={errors}
      isLoading={isLoading}
      form={state.form}
      onSubmitClick={handleSubmit}
    />
  );
};
