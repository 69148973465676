import { field, validate } from '@balance-web/forms';
import type { Form, ValidatedFormValue } from '@balance-web/forms';

export const DevLauncherFormSchema = field.object({
  accessToken: field.text({
    validate: validate((value) => {
      validate.required(value, 'Access Token');
      return value;
    }),
  }),
});

export type DevLauncherFormInstance = Form<typeof DevLauncherFormSchema>;
export type DevLauncherValidatedFormData = ValidatedFormValue<
  typeof DevLauncherFormSchema
>;
