import React from 'react';
import { useSubmit } from '@balance-web/forms';

import { WizardProvider, useCreateWizard } from '@reckon-web/wizard-hooks';

import { useWizardFormStateSync } from '../../../core/Wizard';

import {
  DEVLAUNCHER_WIZARD_KEY,
  DevLauncherWizardSchema,
} from './DevLauncherWizardSchema';
import { DevLauncherWizard } from './DevLauncherWizard';
import { useDevLauncherWizardInitialState } from './useDevLauncherWizardInitialState';
import { useDevLauncherPersistState } from './useDevLauncherPersistState';
import { PickUserWizardStepContainer } from './PickUserStep/PickUserWizardStepContainer';
import { ConfirmWizardStepContainer } from './ConfirmStep/ConfirmWizardStepContainer';

export type DevLauncherPayload = {};

type DevLauncherWizardProps = {
  onFinishClick: (payload: DevLauncherPayload) => void;
};
export const DevLauncherWizardContainer = ({
  onFinishClick,
}: DevLauncherWizardProps) => {
  // Instantiate all forms required by the wizard
  const {
    form,
    initialState,
    initialStep,
  } = useDevLauncherWizardInitialState();
  // Create the wizard ... duh
  const wizard = useCreateWizard<DevLauncherWizardSchema>({
    id: DEVLAUNCHER_WIZARD_KEY,
    initialStep,
    initialState,
    initialAdditionalState: undefined,
  });

  useWizardFormStateSync(wizard, {
    1: { form: initialState[1].form },
  });

  /**
   * Sync the steps that use forms into wizard state by providing step paths to the forms.
   * It will only sync the forms you provide here so if you find one of your forms is not updating, it's probably not supplier here as expected.
   * */
  useDevLauncherPersistState({ wizard });

  const handleFinishWizardClick = useSubmit(form, (formData) => {
    onFinishClick({});
  });

  return (
    <WizardProvider wizard={wizard}>
      <DevLauncherWizard>
        <>
          {wizard.currentStep !== 1 ? null : <PickUserWizardStepContainer />}
          {wizard.currentStep !== 2 ? null : (
            <ConfirmWizardStepContainer
              onSubmitClick={handleFinishWizardClick}
            />
          )}
        </>
      </DevLauncherWizard>
    </WizardProvider>
  );
};
