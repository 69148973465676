/** @jsx jsx */
import React from 'react';
import { jsx } from '@balance-web/core';
import type { ButtonProps } from '@balance-web/button';
import { Button } from '@balance-web/button';
import type { FlexProps } from '@balance-web/flex';
import { Flex } from '@balance-web/flex';

export type Action = ButtonProps &
  JSX.IntrinsicAttributes & {
    action: CallableFunction;
  };

export type ActionFooterProps = FlexProps & {
  size?: Action['size'];
  isInsideDrawer?: boolean;
  actions: (Action | undefined)[];
};

export function ActionFooter({
  actions,
  size,
  gap = 'medium',
  isInsideDrawer,
  ...flexProps
}: ActionFooterProps) {
  return (
    <Flex {...flexProps} gap={gap}>
      {actions
        .filter((action): action is Action => {
          return !!action;
        })
        .map(({ action, label, ...props }, index) => {
          return (
            <Button
              label={label}
              key={label}
              size={props.size || size || 'small'}
              onClick={() => {
                return action();
              }}
              css={{ minWidth: 304 }}
              {...props}
            />
          );
        })}
    </Flex>
  );
}
