import uniqueId from 'lodash/uniqueId';

type LoadScriptProps = {
  id?: string;
  parent: 'head' | 'body';
  defer?: boolean;
  async?: boolean;
} & ({ src: string } | { content: string });

function createScript({
  src,
  contents,
  defer,
  async,
}: {
  src?: string;
  contents?: string;
  defer?: boolean;
  async?: boolean;
}) {
  if (src) {
    const script = document.createElement('script');
    script.setAttribute('src', src);

    if (defer) {
      script.setAttribute('defer', 'true');
    }

    if (async) {
      script.setAttribute('async', 'true');
    }

    return script;
  }

  const script = document
    .createRange()
    .createContextualFragment(`<script>${contents}</script>`)
    .querySelector('script');

  if (!script) {
    throw new Error('Failed to create script element');
  }

  return script;
}

export function LoadScript({
  id,
  parent = 'head',
  defer,
  async,
  ...props
}: LoadScriptProps) {
  return new Promise<Event>((resolve, reject) => {
    const identifier = id || uniqueId('script');

    if (typeof window === 'undefined') {
      return Promise.resolve();
    }

    if (typeof document === 'undefined') {
      return Promise.resolve();
    }

    if (document.querySelector(`script[id="${identifier}"]`)) {
      return Promise.resolve();
    }

    try {
      const s = createScript({
        src: 'src' in props ? props.src : '',
        contents: 'content' in props ? props.content : '',
        defer,
        async,
      });

      s.setAttribute('id', identifier);

      s.addEventListener('load', (event) => {
        return resolve(event);
      });
      s.addEventListener('error', reject);

      if (parent === 'head' && document.head) {
        document.head.appendChild(s);
      }
      if (parent === 'body' && document.body) {
        document.body.appendChild(s);
      }
    } catch (error) {
      reject(error);
    }
  });
}

type ConnectionStatus = 'ready' | 'error';
type ConnectionStatusPromise = Promise<ConnectionStatus>;
type SdkResolver = () => ConnectionStatusPromise;
type SdkLoader<T> = SdkResolver & {
  onReady: (task: (sdk: T) => void) => Promise<void>;
};

export function createSdkResolver<T>(
  connection: () => Promise<Event>,
  getSdk: () => T
) {
  let connectionPromise: ConnectionStatusPromise | null = null;
  let sdk: T | null = null;
  const loader: SdkLoader<T> = async () => {
    if (connectionPromise) {
      return connectionPromise;
    }

    connectionPromise = new Promise<ConnectionStatus>(
      async (resolve, reject) => {
        try {
          const result = await connection();

          if (!result) {
            console.warn('⚠️ Warning: failed to load sdk');
            resolve('error');
            return;
          }

          sdk = getSdk();
          resolve('ready');
        } catch (error) {
          console.error('❌ Failed to load sdk', error);
          resolve('error');
        }
      }
    );

    return connectionPromise;
  };
  loader.onReady = async (task: (sdk: T) => void) => {
    if (!connectionPromise) {
      await loader();
    }

    const status = await connectionPromise;
    if (status === 'ready' && !sdk) {
      sdk = getSdk();
      task(sdk);
    }
    if (status === 'ready' && sdk) {
      task(sdk);
    }
  };

  return loader;
}
