/**
 * @jsx jsx
 */
import type { PropsWithChildren } from 'react';
import React, { useState } from 'react';
import { jsx } from '@balance-web/core';
import { Inline } from '@balance-web/inline';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Button } from '@balance-web/button';
import { Text } from '@balance-web/text';
import { Divider } from '@balance-web/divider';
import { Toggle } from '@balance-web/toggle';
import { FieldLabel } from '@balance-web/field';

import { ReckonOneLogo } from '@reckon-web/application-logos';

import { AppLayout } from '../AppLayout';
import { EmptyState } from '../../common/EmptyState';
import { CodeBlock } from '../../common/CodeBlock';
import { serialiseWizardState } from '../../../core/Wizard';
import { Config } from '../../../services/Config';

import { useUserStepStatus } from './PickUserStep/useUserStepStatus';
import { useDevLauncherWizard } from './useDevLauncherWizard';
import { WizardStepButton } from './WizardStepButton';
type DevLauncherWizardProps = PropsWithChildren<{}>;
export const DevLauncherWizard = ({ children }: DevLauncherWizardProps) => {
  const [stateVisible, setStateVisible] = useState(false);
  const { wizard, resetWizard } = useDevLauncherWizard();
  const authenticatedUserStatus = useUserStepStatus();

  if (!wizard) {
    return null;
  }

  return (
    <AppLayout>
      <EmptyState
        fullScreen
        icon={<ReckonOneLogo size={64} />}
        title="Development Mode"
        content={
          <Stack
            gap="large"
            align="center"
            margin="xxlarge"
            marginTop="medium"
            maxWidth={720}
          >
            <Text align="center">
              To simulate a user arriving here on "
              {Config.APPLICATION_DISPLAY_NAME}", complete the wizard to prepare
              the correct urlhash
            </Text>

            <Divider />

            <Inline gap="medium">
              <WizardStepButton
                step={1}
                {...authenticatedUserStatus}
                title="Authenticate as User"
              />
            </Inline>

            <Stack gap="large" marginTop="large" flexGrow={1} width="100%">
              {children}
            </Stack>

            <Divider />

            <Stack gap="large" marginTop="large" maxWidth={720} width="100%">
              <Flex justifyContent="space-between" width={320} alignSelf="end">
                <FieldLabel htmlFor={'show-state-code'}>
                  Show wizard state
                </FieldLabel>
                <Toggle
                  id={'show-state-code'}
                  onChange={setStateVisible}
                  checked={stateVisible}
                />
              </Flex>
              {stateVisible && (
                <Stack width="100%" gap="large">
                  <Inline align="right">
                    <Button
                      label="reset state"
                      onClick={() => {
                        return resetWizard();
                      }}
                      size="small"
                      block={false}
                    />
                  </Inline>
                  <CodeBlock
                    code={serialiseWizardState({ wizard, prettify: true })}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        }
        actions={[]}
      />
    </AppLayout>
  );
};
