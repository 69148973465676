/**
 * @jsx jsx
 */
import React from 'react';
import { jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { TextList } from '@balance-web/text-list';
import { Inline } from '@balance-web/inline';
import { Button } from '@balance-web/button';
import { Text } from '@balance-web/text';

import { ErrorGuidance } from '../../../common/EmptyState/EmptyState.stories';
import { Loader } from '../../../common/Loader';
import { TextField } from '../../../common/TextField';
import type { UserStepFormInstance } from '../DevLauncherWizardSchema';

export type PickUserWizardStepProps = {
  isLoading?: boolean;
  errors?: string[];
  form: UserStepFormInstance;
  onSubmitClick: () => void;
};

export const PickUserWizardStep = ({
  form,
  errors,
  isLoading,
  onSubmitClick,
}: PickUserWizardStepProps) => {
  if (isLoading) {
    return <Loader label="logging in..." visibleLabel />;
  }

  if (!isLoading && !!errors?.length) {
    return (
      <ErrorGuidance
        title="Oops"
        content={
          <TextList>
            {errors.map((error) => {
              return (
                <Text key={error}>
                  <code>{error}</code>
                </Text>
              );
            })}
          </TextList>
        }
        actions={[]}
      />
    );
  }
  return !!form ? (
    <Stack as="form" gap="small">
      <TextField label="Email" {...form.fields.email.props} />
      <TextField
        label="Password"
        {...form.fields.password.props}
        type="password"
      />
      <Inline alignSelf="end">
        <Button label="Next" type="submit" onClick={onSubmitClick} />
      </Inline>
    </Stack>
  ) : null;
};
