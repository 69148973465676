import React from 'react';
import copy from 'clipboard-copy';

import { ClipboardContext } from './ClipboardContext';

type ClipboardProviderProps = {
  children: React.ReactNode;
  onCopy?: (text: string) => Promise<void>;
};

/**
 * Provides the implementation of copy values to the browsers clipboard.
 *
 * Implementation can be overridden with `onCopy`.
 *
 *
 * @example
 *   <ClipboardProvider onCopy={jest.fn((value: string) => Promise.resolve())}>
 *     <ClipboardButton value='the value to copy' />
 *   </ClipboardProvider>
 */
export const ClipboardProvider = ({
  onCopy,
  children,
}: ClipboardProviderProps) => (
  <ClipboardContext.Provider
    value={{ copy: typeof onCopy === 'function' ? onCopy : copy }}
  >
    {children}
  </ClipboardContext.Provider>
);
