import React, { forwardRef } from 'react';
import { Field, FieldProps } from '@balance-web/field';
import { TextInput, TextInputProps } from '@balance-web/text-input';

export type TextFieldProps = FieldProps & TextInputProps;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      description,
      helpText,
      id,
      invalidMessage,
      label,
      labelVisible,
      reserveMessageSpace,
      ...inputProps
    },
    inputRef
  ) {
    return (
      <Field
        description={description}
        helpText={helpText}
        id={id}
        invalidMessage={invalidMessage}
        label={label}
        labelVisible={labelVisible}
        reserveMessageSpace={reserveMessageSpace}
        {...((invalidMessage && { 'aria-invalid': true }) || {})}
      >
        <TextInput ref={inputRef} {...inputProps} />
      </Field>
    );
  }
);
