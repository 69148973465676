import { field, validate } from '@balance-web/forms';
import type { Form, ValidatedFormValue } from '@balance-web/forms';

export const DEVLAUNCHER_WIZARD_KEY = 'DEVLAUNCHER_WIZARD';
export const DEVLAUNCHER_WIZARD_LOCALSTORAGE_KEY = `${DEVLAUNCHER_WIZARD_KEY}_state`;

export const DEVLAUNCHER_WIZARD_INITIAL_STATE = {
  1: {},
  2: {},
};

export const UserStepSchema = field.object({
  email: field.text({
    validate: validate((value) => {
      validate.required(value, 'Email');
      return value;
    }),
  }),

  password: field.text({
    validate: validate((value) => {
      validate.required(value, 'Password');
      return value;
    }),
  }),
});
export type UserStepFormInstance = Form<typeof UserStepSchema>;
export type UserStepFormData = ValidatedFormValue<typeof UserStepSchema>;

export const DevLauncherFormSchema = field.object({
  stepOne: UserStepSchema,
});

export type DevLauncherFormInstance = Form<typeof DevLauncherFormSchema>;
export type DevLauncherFormData = ValidatedFormValue<
  typeof DevLauncherFormSchema
>;

export type DevLauncherWizardSchema = {
  1: {
    form: Form<typeof UserStepSchema>;
    token?: string;
  };
  2: {
    confirmed?: boolean;
  };
};
