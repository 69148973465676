import { createContext } from 'react';
import copy from 'clipboard-copy';

/**
 * Clipboard Context.
 *
 * Holds the clipboard copying implementation, which must be an async function that takes a string.
 */
export const ClipboardContext = createContext<{
  copy?: typeof copy;
}>({});
