import { useCallback } from 'react';

import { WizardStepHook, WizardStepHookProps } from '../../../../core/Wizard';
import { useDevLauncherWizard } from '../useDevLauncherWizard';
import { DevLauncherWizardSchema } from '../DevLauncherWizardSchema';

import { useUserStepStatus } from './useUserStepStatus';

export const useUserStep = ({
  initialiseOnMount,
}: WizardStepHookProps = {}): WizardStepHook<DevLauncherWizardSchema, 1> => {
  const { wizard } = useDevLauncherWizard();
  const state = wizard.stepsState[1];
  const { isInitialised, isComplete } = useUserStepStatus();

  const initialise = useCallback(async () => {}, []);

  const isInitialising = false;

  return {
    state,
    initialise,
    isInitialising,
    isInitialised,
    isComplete,
  };
};
