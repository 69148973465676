import React, { createContext, useContext, useState } from 'react';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import Head from 'next/head';
import { useTheme } from '@balance-web/theme';

import { urlWithPublicPrefix } from '@reckon-web/next-config/client';

import { Config } from '../../../services/Config';

type MetaHeadContextShape = {
  currentPageTitle: string | null;
  setCurrentPageTitle: Dispatch<SetStateAction<string>>;
};
const MetaHeadContext = createContext<MetaHeadContextShape>({
  currentPageTitle: null,
  setCurrentPageTitle: () => {},
});

type MetaHeadProps = PropsWithChildren<{
  appName: string;
  version: string;
}>;

export function MetaHead({ appName, version, children }: MetaHeadProps) {
  const { colors } = useTheme();
  const [currentPageTitle, setCurrentPageTitle] = useState('');
  return (
    <MetaHeadContext.Provider value={{ currentPageTitle, setCurrentPageTitle }}>
      <Head>
        {!currentPageTitle && <title>{appName}</title>}
        {!!currentPageTitle && (
          <title>
            {appName} / {currentPageTitle}
          </title>
        )}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="version" content={version} />
        <link
          rel="shortcut icon"
          href={urlWithPublicPrefix(Config.APPLICATION_FAVICON_URL)}
        />
        <meta name="msapplication-TileColor" content={colors.accent} />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${Config.APPLICATION_GOOGLE_TAGID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${Config.APPLICATION_GOOGLE_TAGID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </Head>
      {children}
    </MetaHeadContext.Provider>
  );
}

export function useMetaHead() {
  const context = useContext(MetaHeadContext);
  if (!context) {
    throw new Error(
      'useMetaHead must only be used within children of MetaHead'
    );
  }
  return context;
}
