/** @jsx jsx */
import React, { Fragment, createContext, useMemo } from 'react';
import { jsx } from '@balance-web/core';

import { useAuth } from '../../../services/Auth';
import { isSecureRoute } from '../../../services/Navigation';

import { useDevLauncherStepUrlParam } from './useDevLauncherPersistState';
import { DevLauncherWizardContainer } from './DevLauncherWizardContainer';
import type { DevLauncherValidatedFormData } from './DevLauncherFormSchema';

export const DevLauncherContext = createContext<
  DevLauncherValidatedFormData | undefined
>(undefined);

type DevLauncherProviderProps = {
  currentPath: string;
  visible?: boolean;
  children: React.ReactNode;
};

/**
 * App Dev Launcher
 *
 * A wizard that replaces the child render tree when the below conditions are met.
 *
 * This wizard should allow local developers to avoid having to travel the
 * world in order to prepare perfect access conditions to the app.
 */
export const DevLauncherProvider = ({
  currentPath,
  visible,
  children,
}: DevLauncherProviderProps) => {
  const { credentials } = useAuth();
  const { setStepUrlParam } = useDevLauncherStepUrlParam();

  /**
   * Devlauncher Visibility Conditions
   */
  const isSatisfied = useMemo(() => {
    return !!credentials?.accessToken;
  }, [credentials?.accessToken]);

  if (!isSatisfied && !!visible && isSecureRoute(currentPath)) {
    return (
      <DevLauncherWizardContainer
        onFinishClick={() => {
          setStepUrlParam();
        }}
      />
    );
  }

  return <Fragment>{children}</Fragment>;
};
