import type { Field, Form } from '@balance-web/forms';

import type { WizardType } from '@reckon-web/wizard-hooks';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * Describes the return type of a step hook.
 */
export type WizardStepHook<
  TWizardSchema,
  TStepNumber extends keyof WizardType<TWizardSchema>['stepsState']
> = {
  isComplete: boolean;
  isInitialised: boolean;
  isInitialising: boolean;
  initialise: <TReturn extends any = void>() => Promise<TReturn | void>;
  state: WizardType<TWizardSchema>['stepsState'][TStepNumber];
};

export type WizardStepHookProps = {
  initialiseOnMount?: boolean;
};

export type WizardState<
  TSteps,
  TAdditionalState extends object | void = void
> = {
  stepsState: TSteps;

  additionalState: TAdditionalState;
};

export const isForm = (value: unknown): value is Form<Field> => {
  if (typeof value === 'object' && value !== null) {
    return (
      hasKey('_field', value) &&
      hasKey('value', value) &&
      !!value._field &&
      value.value !== undefined
    );
  }
  return false;
};

export const assertIsForm = (value: unknown): asserts value is Form<Field> => {
  if (!isForm(value)) {
    throw new Error('provided value is not a form');
  }
};

export function hasKey<K extends string>(k: K, o: {}): o is { [_ in K]: {} } {
  return typeof o === 'object' && k in o;
}
