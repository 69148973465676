import { useLocalstorageState } from 'rooks';
import { useForm } from '@balance-web/forms';

import type { WizardState } from '../../../core/Wizard';

import { useDevLauncherStepUrlParam } from './useDevLauncherPersistState';
import {
  DEVLAUNCHER_WIZARD_INITIAL_STATE,
  DEVLAUNCHER_WIZARD_LOCALSTORAGE_KEY,
  DevLauncherFormSchema,
  DevLauncherWizardSchema,
} from './DevLauncherWizardSchema';
export const useDevLauncherWizardInitialState = () => {
  const { stepUrlParam: initialStep } = useDevLauncherStepUrlParam();
  const [serialisedState] = useLocalstorageState<any>(
    DEVLAUNCHER_WIZARD_LOCALSTORAGE_KEY
  );

  // If state was persisted to local storage it will resume where it left off
  const resumedState = serialisedState?.length
    ? JSON.parse(serialisedState)
    : (undefined as WizardState<DevLauncherWizardSchema> | undefined);

  const form = useForm(DevLauncherFormSchema, {
    stepOne: resumedState?.stepsState && resumedState?.stepsState[1].form.value,
  });

  const resumedStepsState = resumedState?.stepsState || {};

  const initialState = {
    1: {
      ...DEVLAUNCHER_WIZARD_INITIAL_STATE[1],
      ...resumedStepsState[1],
      form: form.fields.stepOne,
    },
    2: { confirmed: false },
  };

  return {
    form,
    initialStep,
    initialState,
  };
};
