import React from 'react';
import type { ReactNode } from 'react';
import type { CSSObject } from '@balance-web/core';

import { ThemeProvider } from './ThemeProvider';
import { PackageProviders } from './PackageProviders';
import type { ThemeProviderProps } from './ThemeProvider';

type ReckonBalanceCoreProps = {
  themeMode: 'light';
  children?: ReactNode;
  globalStyles?: CSSObject;
} & Pick<ThemeProviderProps, 'linkComponent' | 'themeOverrides'>;

export const ReckonBalanceCore = ({
  themeOverrides,
  linkComponent,
  globalStyles,
  children,
}: ReckonBalanceCoreProps) => {
  return (
    <ThemeProvider
      linkComponent={linkComponent}
      globalStyles={globalStyles}
      themeOverrides={themeOverrides}
    >
      <PackageProviders>{children}</PackageProviders>
    </ThemeProvider>
  );
};
