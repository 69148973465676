/** @jsx jsx */

import type { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { forwardRef } from 'react';
import { VisuallyHidden } from '@balance-web/a11y';
import { jsx } from '@balance-web/core';
import { useRawTheme, useTheme } from '@balance-web/theme';

export type ToggleProps = {
  /** Optionally pass "On" and "Off" text for screen readers. */
  a11yText?: { on: string; off: string };
  /** The current checked state. */
  checked?: boolean;
  /** Set the toggle to read only. This is different from disabled because the element is still accessible. */
  readOnly?: boolean;
  /** Optionally provide an ID. */
  id?: string;
  /** Handle change events. */
  onChange?: (checked: boolean) => void;
} & Omit<ButtonProps, 'onChange'>;

export const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  (
    {
      a11yText = { on: 'On', off: 'Off' },
      checked = false,
      disabled = false,
      readOnly = false,
      id,
      size = 'medium',
      onChange,
      ...props
    },
    ref
  ) => {
    // NOTE: we don't want to _actually_ disable the button element because that
    // would make it inaccessible — it must be focusable for users of assistive
    // tech. By moving the disabled logic to our click handler we provide a
    // solution that supports all users.
    const handleClick: MouseEventHandler = (event) => {
      if (disabled || readOnly) {
        event.preventDefault();
      } else if (onChange) {
        onChange(!checked);
      }
    };

    return (
      <Button
        aria-checked={checked}
        aria-disabled={disabled}
        aria-readonly={readOnly}
        id={id}
        onClick={handleClick}
        ref={ref}
        role="switch"
        type="button"
        // appearance props
        size={size}
        readOnly={readOnly}
        disabled={disabled}
        {...props}
      >
        <VisuallyHidden>{checked ? a11yText.on : a11yText.off}</VisuallyHidden>
      </Button>
    );
  }
);

Toggle.displayName = 'Toggle';

// Styled Components
// ------------------------------

// TODO move to tokens?
const animationEasing = {
  spring: `cubic-bezier(0.2, 0, 0, 1.6)`,
  easeIn: `cubic-bezier(0.2, 0, 0, 1)`,
  easeOut: `cubic-bezier(0.165, 0.840, 0.440, 1.000)`, // quart
};

type ButtonProps = {
  /** The size of the toggle. */
  size?: 'small' | 'medium';
  /** Sets the toggle to read only */
  readOnly?: boolean;
  /** Sets the toggle to be disabled */
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ size = 'medium', readOnly, disabled, children, ...props }, ref) => {
    const theme = useTheme();
    const rawTheme = useRawTheme();
    const sizeMap = {
      medium: {
        trackHeight: 32,
        trackWidth: 52,
        handleOffSize: 16,
        handleOnSize: 28,
        readOnlyHandleOnSize: 24,
        handleOffLeftOffset: 4,
        handleOnLeftOffset: 20,
        readOnlyHandleOnLeftOffset: 22,
      },
      small: {
        trackHeight: 24,
        trackWidth: 40,
        handleOffSize: 12,
        handleOnSize: 20,
        readOnlyHandleOnSize: 16,
        handleOffLeftOffset: 4,
        handleOnLeftOffset: 16,
        readOnlyHandleOnLeftOffset: 18,
      },
    };

    // When both disabled and readOnly are provided, disabled wins
    const isReadOnly = !disabled && readOnly;

    let gutter = rawTheme.spacing.xxsmall;

    return (
      <button
        ref={ref}
        css={{
          backgroundColor: theme.primitives.colour.surface.default.k4,
          border: 0,
          borderRadius: 9999,
          boxSizing: 'border-box',
          cursor: 'pointer',
          display: 'block',
          height: sizeMap[size].trackHeight,
          outline: 0,
          overflow: 'hidden',
          position: 'relative',
          transition: `background 240ms`,
          whiteSpace: 'nowrap',
          width: sizeMap[size].trackWidth,

          '&[aria-checked="true"]': {
            backgroundColor: theme.primitives.colour.reserved.success.k60,
            '.toggle-inner-track': {
              boxShadow: `inset 0 0 0 2px ${theme.primitives.colour.reserved.success.k60}`,

              '::before': {
                height:
                  sizeMap[size][
                    isReadOnly ? 'readOnlyHandleOnSize' : 'handleOnSize'
                  ],
                width:
                  sizeMap[size][
                    isReadOnly ? 'readOnlyHandleOnSize' : 'handleOnSize'
                  ],
                transform: `translateX(${
                  sizeMap[size][
                    isReadOnly
                      ? 'readOnlyHandleOnLeftOffset'
                      : 'handleOnLeftOffset'
                  ]
                }px)`,
                backgroundColor: theme.primitives.colour.reserved.success.k100,
              },
            },
          },

          '&[aria-disabled="true"]': {
            backgroundColor:
              theme.primitives.colour.neutral.interactions.inverse.light,
            cursor: 'not-allowed',

            '.toggle-inner-track': {
              boxShadow: `inset 0 0 0 2px ${theme.primitives.colour.neutral.interactions.inverse.light}`,
              ':before': {
                backgroundColor:
                  theme.primitives.colour.neutral.interactions.container.high,
              },
            },

            '&[aria-checked="true"]': {
              backgroundColor:
                theme.primitives.colour.neutral.interactions.inverse.light,
              '.toggle-inner-track': {
                boxShadow: 'none',
                ':before': {
                  backgroundColor:
                    theme.primitives.colour.reserved.success.k100,
                },
              },
            },
          },

          '&[aria-readonly="true"]:not([aria-disabled=true])': {
            backgroundColor: theme.primitives.colour.neutral.k100,
            cursor: 'default',

            '.toggle-inner-track': {
              boxShadow: `inset 0 0 0 2px ${theme.primitives.colour.neutral.k40}`,
              ':before': {
                backgroundColor: theme.primitives.colour.neutral.k100,
                boxShadow: `inset 0 0 0 2px ${theme.primitives.colour.neutral.k40}`,
              },
            },

            '&[aria-checked="true"]': {
              backgroundColor: theme.primitives.colour.neutral.k100,
              '.toggle-inner-track': {
                boxShadow: `inset 0 0 0 2px ${theme.primitives.colour.neutral.k40}`,
                ':before': {
                  backgroundColor:
                    theme.primitives.colour.reserved.success.k100,
                },
              },
            },
          },

          '&:not([aria-disabled=true],[aria-readonly=true])': {
            '&.focus-visible, &:active': {
              boxShadow: `0 0 0 6px ${theme.primitives.colour.neutral.interactions.inverse.mid}`,
            },
            '&:not(.focus-visible, :active):hover': {
              boxShadow: `0 0 0 6px ${theme.primitives.colour.neutral.interactions.inverse.light}`,
            },
            '&[aria-checked="true"]': {
              '&.focus-visible, &:active': {
                boxShadow: `0 0 0 6px ${theme.primitives.colour.reserved.success.interactions.inverse.mid}`,
              },
              '&:not(.focus-visible, :active):hover': {
                boxShadow: `0 0 0 6px ${theme.primitives.colour.reserved.success.interactions.inverse.light}`,
              },
            },
          },
        }}
        {...props}
      >
        <div
          className="toggle-inner-track"
          css={{
            width: '100%',
            height: '100%',
            boxShadow: `inset 0 0 0 2px ${theme.primitives.colour.neutral.k50}`,
            borderRadius: 999,
            padding: gutter,
            outline: 0,
            overflow: 'hidden',
            border: 0,
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',

            '::before': {
              height: sizeMap[size].handleOffSize,
              width: sizeMap[size].handleOffSize,

              transform: `translateX(${sizeMap[size].handleOffLeftOffset}px)`,
              backgroundColor: theme.primitives.colour.neutral.k50,
              borderRadius: '50%',
              content: '" "',
              display: 'block',
              transition: `transform 240ms ${animationEasing.easeOut}, height 240ms ${animationEasing.easeOut}, width 240ms ${animationEasing.easeOut}`,
            },
          }}
        >
          {children}
        </div>
      </button>
    );
  }
);

Button.displayName = 'Button';
