/** @jsx jsx */
import React from 'react';
import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { Stack } from '@balance-web/stack';
import { Box } from '@balance-web/box';
import { Flex } from '@balance-web/flex';

import type { Action } from '../ActionFooter';
import { ActionFooter } from '../ActionFooter';

export type EmptyStateProps = {
  title: string;
  image?: React.ReactNode;
  isInsideDrawer?: boolean;
  icon?: React.ReactNode;
  content?: React.ReactNode;
  actions?: (Action | undefined)[];
  actionSize?: Action['size'];
  fullScreen?: boolean;
};

// NOTE: this requires the containing element to be positioned "relatively"
export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const {
    actions = [],
    actionSize,
    image,
    isInsideDrawer,
    content,
    title,
    icon,
    fullScreen,
  } = props;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexGrow={(fullScreen && 1) || undefined}
    >
      <Stack gap="large" align="center">
        {image}

        {icon && <Box paddingBottom="large">{icon}</Box>}

        <Stack align="center">
          <Heading level="5">{title}</Heading>
          {content}
        </Stack>

        <ActionFooter
          actions={actions}
          size={actionSize}
          direction="column"
          isInsideDrawer={isInsideDrawer}
        />
      </Stack>
    </Flex>
  );
};
